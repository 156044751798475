function isProd() {
    return process.env.NODE_ENV === 'production';
}

export const config = {
    env: isProd() ? "production" : "development",
    apiEndpoint: "https://backend-staging.aulamiri.it",
    apiBaseUri: '',
    mediaEndpoint: "https://backend-staging.aulamiri.it/media/",

    isMiri() {
        return window.location.href.indexOf('aulamiri.it') != -1;
    },

    isPinguin() {
        return window.location.href.indexOf('tavolopinguin.it') != -1;
    },

    isComposer() {
        return window.location.href.indexOf('https://composer.') != -1 || window.location.href.indexOf('https://staging-composer.') != -1 || window.location.href.indexOf('https://www.composer.') != -1 || window.location.href.indexOf('https://www.staging-composer.') != -1;
    },

    isAdmin() {
        return window.location.href.indexOf('https://adm.') != -1 || window.location.href.indexOf('https://staging-adm.') != -1 || window.location.href.indexOf('https://www.adm.') != -1 || window.location.href.indexOf('https://www.staging-adm.') != -1;
    },

    isAccount() {
        return window.location.href.indexOf('https://account.') != -1 || window.location.href.indexOf('https://staging-account.') != -1 || window.location.href.indexOf('https://www.account.') != -1 || window.location.href.indexOf('https://www.staging-account.') != -1;
    },

    isApp() {
        return window.location.href.indexOf('https://app.') != -1 || window.location.href.indexOf('https://staging-app.') != -1 || window.location.href.indexOf('https://www.app.') != -1 || window.location.href.indexOf('https://www.staging-app.') != -1;
    },

    getEndpointUri() {
        return `${config.apiEndpoint}${config.apiBaseUri}`
    }
}
